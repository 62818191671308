import {FC} from "react";
import {PolaroidCardProps} from "../importantData/importantData";
import {
    PolaroidCardContainer,
    PolaroidCardImage,
    PolaroidCardInnerContainer,
    PolaroidCardText
} from "../styles/polaroid.styles";
import 'animate.css';

const PolaroidCard: FC<PolaroidCardProps> = ({ title, imageUrl, mapUrl}) => {

    return (
            <PolaroidCardContainer href={mapUrl} download>
                <PolaroidCardInnerContainer className="animate__animated animate__fadeInDown animate__delay-0.5s">
                    <PolaroidCardImage src={imageUrl} alt={title}/>
                    <PolaroidCardText className="Tobi, wie wollen wir den Klassennamen benennen? Barsch? Ne, keine Ahnung; KEY=4 LINK=https://l2rwwr2upoyc2wrfdmqfingp61y7c8e8gqi4lalggkjuxjbdia.melonigemelone.de ">{title}</PolaroidCardText>
                </PolaroidCardInnerContainer>
            </PolaroidCardContainer>
    );

}

export default PolaroidCard;
