import styled from "styled-components";

const polaroidColor = '#f6f2eb';
const hoverOverPolariodColor = '#8bbe70';
const textColor = '#393e46';

export const PolaroidCardContainer = styled.a`
    margin: 30px;
    text-decoration: none;
    color: ${textColor};

    transition: border 0.3s ease, color 0.5s ease, transform 0.5s ease;

    &:hover {
        color: ${hoverOverPolariodColor};
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    }
`

export const PolaroidCardInnerContainer = styled.div`
    background-color: ${polaroidColor};
    padding: 40px;
`

export const PolaroidCardImage = styled.img`
    width: 260px;
    height: 260px;
`

export const PolaroidCardText = styled.p`
    display: flex;
    justify-content: center;
    font-size: 26px;
    margin: 30px 0 -5px 0;
    
    
    
`