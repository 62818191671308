import {minecraftMaps} from "./importantData/importantData";
import PolaroidCard from "./components/polaroidCard.component";
import {
    AppContainer,
    AppHeader,
    AppHeaderContainer,
    OuterPolaroidCardContainer,
    SmallerAppHeader
} from "./styles/app.styles";
import 'animate.css';

const App = () => {

  return (
      <AppContainer>
        <AppHeaderContainer>
            <AppHeader>Survival Projekt Map-Download</AppHeader>
            <SmallerAppHeader>Click on an image for the download</SmallerAppHeader>
        </AppHeaderContainer>
        <OuterPolaroidCardContainer>
          {minecraftMaps.map((minecraftMap, index) => {
            return (
                <PolaroidCard key={index} title={minecraftMap.title} imageUrl={minecraftMap.imageUrl}
                              mapUrl={minecraftMap.mapUrl}/>
            );
          })}
        </OuterPolaroidCardContainer>
      </AppContainer>
  );
}

export default App;
