import styled from "styled-components";

const textColor = '#26272d';

export const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 72vh;
`

export const AppHeaderContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 10vh;
`

export const AppHeader = styled.h1`
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: 4px;
    color: ${textColor};
    font-size: 60px;
    cursor: default;
`

export const SmallerAppHeader = styled.h2`
    text-align: center;
    color: ${textColor};
    font-size: 26px;
    margin-top: -30px;
    cursor: default;
`

export const OuterPolaroidCardContainer = styled.div`
    display: flex;   
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
`