// @ts-ignore
import image1 from "./images/1.16_image.png";
// @ts-ignore
import image2 from "./images/1.17_image.png";
// @ts-ignore
import image3 from "./images/1.18_image.png";
// @ts-ignore
import image4 from "./images/1.19_image.png";

export type PolaroidCardProps = {
    title: string
    imageUrl: string
    mapUrl: string
}

export const minecraftMap1: PolaroidCardProps = {
    title: "Season 1 (1.16)",
    imageUrl: image1,
    mapUrl: "maps/map_1_16.zip",
}

export const minecraftMap2: PolaroidCardProps = {
    title: "Season 2 (1.17)",
    imageUrl: image2,
    mapUrl: "maps/map_1_17.zip",
}

export const minecraftMap3: PolaroidCardProps = {
    title: "Season 3 (1.18)",
    imageUrl: image3,
    mapUrl: "maps/map_1_18.zip",
}

export const minecraftMap4: PolaroidCardProps = {
    title: "Season 4 (1.19)",
    imageUrl: image4,
    mapUrl: "maps/map_1_19.zip",
}

export const minecraftMaps: PolaroidCardProps[] = [
    minecraftMap1,
    minecraftMap2,
    minecraftMap3,
    minecraftMap4,
]

